import Clickable from 'components/Common/Clickable'
import LuxLoyaltyTierIcon from 'components/Luxkit/Icons/luxLoyalty/LuxLoyaltyTierIcon'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Group from 'components/utils/Group'
import InlineIcon from 'components/utils/InlineIcon'
import FormatLuxLoyaltyPoints from 'luxLoyalty/components/Formatters/FormatLuxLoyaltyPoints'
import FormatLuxLoyaltyStatusCredits from 'luxLoyalty/components/Formatters/FormatLuxLoyaltyStatusCredits'
import React, { ComponentProps, MouseEventHandler, useCallback } from 'react'

interface Props {
  tier: App.LuxLoyaltyTier
  earnablePoints: number
  statusCredits?: number
  size: 'S' | 'M'
  onInfoIconClick?: MouseEventHandler<HTMLButtonElement>
  calculationType: 'estimate' | 'exact' | 'additional'
}

const SIZES_BODY_TEXT_VARIANTS: Record<Props['size'], ComponentProps<typeof BodyTextBlock>['variant']> = {
  S: 'small',
  M: 'medium',
}

function PriceRowLuxLoyaltyPoints({
  tier,
  earnablePoints,
  statusCredits,
  size,
  onInfoIconClick,
  calculationType,
}: Props) {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()

    onInfoIconClick?.(event)
  }, [onInfoIconClick])

  return <BodyTextBlock
    variant={SIZES_BODY_TEXT_VARIANTS[size]}
    colour="neutral-one"
    weight="bold"
    startIcon={<LuxLoyaltyTierIcon size="S" tier={tier} />}
  >
    <Group direction="horizontal" wrap="wrap" gap={4}>
      <span>
        {calculationType === 'estimate' && <>Earn from <FormatLuxLoyaltyPoints abbreviated points={earnablePoints} /></>}
        {calculationType === 'exact' && <>Earn <FormatLuxLoyaltyPoints abbreviated points={earnablePoints} /></>}
        {calculationType === 'additional' && <>Earn an additional <FormatLuxLoyaltyPoints abbreviated points={earnablePoints} /></>}
      </span>
      {!!statusCredits && <span> + <FormatLuxLoyaltyStatusCredits statusCredits={statusCredits} /></span> }
      {onInfoIconClick && <> <Clickable onClick={handleClick}><InlineIcon Icon={SolidInfoCircleIcon} /></Clickable></>}
    </Group>
  </BodyTextBlock>
}

export default PriceRowLuxLoyaltyPoints
