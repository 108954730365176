import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import React, { useMemo } from 'react'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import OfferBadgeLabel from 'components/Common/OfferBadgeLabel/OfferBadgeLabel'
import LEOfferPriceDetails from './LEOfferPriceDetails'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { isUltraLuxOffer } from 'lib/offer/offerUtils'
import { checkCanViewLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import LuxPlusHiddenOfferCarouselCard from 'luxPlus/components/HiddenOffer/LuxPlusHiddenOfferCarouselCard'
import { connect } from 'react-redux'
import { isLuxPlusHiddenOffer } from 'luxPlus/selectors/luxPlusHiddenOffer'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import { getInclusionsViewableLuxPlusTier } from 'selectors/luxPlusSelectors'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import OfferPartnershipLabel from 'components/Common/Labels/OfferPartnershipLabel'
import BookingsCountLabel from 'components/Common/Labels/BookingsCountLabel'
import { useAppSelector } from 'hooks/reduxHooks'
import useOffersBestPrices from 'hooks/useOfferBestPrices'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPriceNew'
import SaveBannerLabel from 'components/Common/Labels/SaveBannerLabel'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import { generateLuxLoyaltyPointsCalculatorHotelOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'
import { isHotelPackage } from 'lib/offer/offerTypes'

interface MappedProps {
  isLuxPlusEnabled: boolean;
  isLuxPlusHiddenOffer: boolean;
  inclusionsViewableLuxPlusTier: App.MembershipTier;
}

interface Props extends MappedProps {
  offer: App.HotelOffer | App.HotelOfferSummary
  onClick?: () => void
  className?: string;
  offerUrl: string;
  size: 'small' | 'medium';
  selectedDatesAndRoomsFilter?: App.OfferListFilters;
  isUrgencySavingUpsPricingHomepageSearch?: boolean;
}

function LEOfferCard(props: Props) {
  const {
    offer,
    size,
    onClick,
    className,
    offerUrl,
    isLuxPlusHiddenOffer,
    isLuxPlusEnabled,
    selectedDatesAndRoomsFilter,
    inclusionsViewableLuxPlusTier,
    isUrgencySavingUpsPricingHomepageSearch,
  } = props
  const { lowestPricePackage } = offer
  const isUltraLux = isUltraLuxOffer(offer)
  const memberPrice = lowestPricePackage?.memberPrice ?? 0
  const showMemberPrice = isLuxPlusEnabled && memberPrice > 0
  const saveAmount = showMemberPrice ? (lowestPricePackage?.price ?? 0) - memberPrice : undefined
  const luxPlusInclusions = lowestPricePackage?.luxPlusInclusionsByTier?.[inclusionsViewableLuxPlusTier as App.MembershipSubscriptionTier]
  const offerLabels = useMemo(() => offer.urgencyTags.filter(label => label.type !== 'left'), [offer])
  const timeRemainingLabel = useMemo(() => offer.urgencyTags.find(label => label.type === 'left'), [offer])

  const canViewLuxPlusBenefits = useAppSelector(checkCanViewLuxPlusBenefits)
  const bestPkg = useOffersBestPrices(offer, selectedDatesAndRoomsFilter || {})
  const pkg = bestPkg?.bestPricePackage ?? offer.lowestPricePackage!
  const {
    default: {
      discountPercent,
    },
    member: memberPricing,
  } = useOfferPackagePrice(pkg, offer)
  const discountToDisplay = canViewLuxPlusBenefits && memberPricing ? memberPricing.discountPercent : discountPercent
  const SaveBanner = useMemo(() => {
    if (isUrgencySavingUpsPricingHomepageSearch && !offer.isDiscountPillHidden && discountToDisplay >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE) {
      return <SaveBannerLabel discountToDisplay={discountToDisplay} />
    }
    return undefined
  }, [discountToDisplay, isUrgencySavingUpsPricingHomepageSearch, offer.isDiscountPillHidden])
  const isMobileScreen = useScreenSizeOnly('mobile')

  const pointsEarnCalculationRequests = useMemo<Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>>(() => {
    if (offer.bundledWithFlightsOnly) {
      return []
    }
    return [
      generateLuxLoyaltyPointsCalculatorHotelOptions(offer, {
        pkg: isHotelPackage(pkg) ? pkg : undefined,
        price: pkg.price,
        memberPrice: pkg.memberPrice,
      }),
    ]
  }, [offer, pkg])

  let imageCornerLabel = <BookingsCountLabel bookings={offer.stats.booked} threshold="offer" />
  if (isUltraLux) {
    imageCornerLabel = <ProductTypeLabel productType={offer.productType} />
  }

  return (
    <ProductPaletteProvider product={offer} >
      {isLuxPlusHiddenOffer && <LuxPlusHiddenOfferCarouselCard urgencyLabels={offerLabels} offer={offer} />}
      {!isLuxPlusHiddenOffer && <>
        {size === 'medium' && <CarouselCardMedium
          data-testid="offer-card"
          className={className}
          bookmarkButton={<BookmarkButton offer={offer} iconOnly={!!((isMobileScreen && isUrgencySavingUpsPricingHomepageSearch))} />}
          image={offer.image}
          location={[offer.locationHeading, offer.locationSubheading].filter((t) => t).join(', ')}
          providerName={offer.property.name}
          title={offer.name}
          priceDetails={<LEOfferPriceDetails variant="condensed" offer={offer} selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter} isUrgencySavingUpsPricingHomepageSearch={isUrgencySavingUpsPricingHomepageSearch}/>}
          to={offerUrl}
          rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
          timeRemainingLabel={timeRemainingLabel}
          partnershipLabel={<OfferPartnershipLabel offer={offer}/>}
          urgencyLabels={<LabelGroup>
            <OfferLabels
              offer={offer}
              urgencyLabels={offerLabels}
              saveAmount={saveAmount}
              showMemberInclusionLabels
              luxPlusInclusions={luxPlusInclusions}
              leadWithLuxPlusPrice
            />
            {offer.badge && <OfferBadgeLabel badge={offer.badge} />}
          </LabelGroup>}
          loyaltyPointsElement={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
          saveBanner={SaveBanner}
          onClick={onClick}
          imageCornerLabel={imageCornerLabel}
        />}
        {size === 'small' && <CarouselCardSmall
          data-testid="offer-card"
          className={className}
          bookmarkButton={<BookmarkButton offer={offer} />}
          description={offer.name}
          image={offer.image}
          location={[offer.locationHeading, offer.locationSubheading].filter((t) => t).join(', ')}
          title={offer.property.name}
          priceDetails={<LEOfferPriceDetails offer={offer} selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter} />}
          to={offerUrl}
          productType={offer.productType}
          rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
          partnershipLabel={<OfferPartnershipLabel offer={offer}/>}
          urgencyLabels={<LabelGroup>
            <OfferLabels
              offer={offer}
              saveAmount={saveAmount}
              showMemberInclusionLabels
              luxPlusInclusions={luxPlusInclusions}
              leadWithLuxPlusPrice
            />
            {offer.badge && <OfferBadgeLabel badge={offer.badge} />}
          </LabelGroup>}
          loyaltyPointsElement={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
          onClick={onClick}
          imageCornerLabel={imageCornerLabel}
        />}
      </>}
    </ProductPaletteProvider>
  )
}

function mapStateToProps(state: App.State, ownProps: Partial<Props>): MappedProps {
  return ({
    isLuxPlusEnabled: isLuxPlusEnabled(state),
    isLuxPlusHiddenOffer: isLuxPlusHiddenOffer(state, ownProps.offer),
    inclusionsViewableLuxPlusTier: getInclusionsViewableLuxPlusTier(state),
  })
}

export default connect(mapStateToProps)(LEOfferCard)
