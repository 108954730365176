import getItemViewTotalsPrice from 'checkout/lib/utils/getItemViewTotalsPrice'
import getItemViewAppliedLoyaltyUpgrade from 'checkout/lib/utils/luxLoyalty/getItemViewAppliedLoyaltyUpgrade'
import { EmptyMap } from 'lib/array/arrayUtils'
import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import getAllItemViews from '../view/getAllItemViews'
import { checkoutWithMemberPrice, selectShouldUseBookingProtectionMemberPrice, selectShouldUseInsuranceMemberPrice } from '../view/luxPlusSubscription'
import getItemViewAppliedLoyaltyDiscount from 'checkout/lib/utils/luxLoyalty/getItemViewAppliedLoyaltyDiscount'

interface TotalsPricing {
  price: number
  priceExcludingLoyaltyBenefits?: number
}

const getCheckoutLuxLoyaltyItemsPriceInCurrency = createSelector(
  (state: App.State) => getAllItemViews(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (state: App.State) => selectShouldUseInsuranceMemberPrice(state),
  (state: App.State) => selectShouldUseBookingProtectionMemberPrice(state),
  (
    allItemViews,
    canCheckoutWithMemberPrice,
    shouldUseInsuranceMemberPrice,
    shouldUseBookingProtectionMemberPrice,
  ): Map<string, TotalsPricing> => {
    if (!allItemViews.hasRequiredData) return EmptyMap

    const priceByItem: Map<string, TotalsPricing> = new Map()

    allItemViews.data.forEach((itemView) => {
      const view = itemView.data

      if (view.luxLoyaltyProductType) {
        let priceInCurrency: number
        let priceExcludingLoyaltyBenefits: number | undefined

        switch (view.luxLoyaltyProductType) {
          case 'insurance':
            const insuranceTotal = getItemViewTotalsPrice(view.totals, shouldUseInsuranceMemberPrice)
            const loyaltyInsuranceDiscount = getItemViewAppliedLoyaltyDiscount(view)
            priceInCurrency = insuranceTotal
            priceExcludingLoyaltyBenefits = insuranceTotal + loyaltyInsuranceDiscount
            break
          case 'cfmr':
            const cfmrTotal = getItemViewTotalsPrice(view.totals, shouldUseBookingProtectionMemberPrice)
            const loyaltyCfmrDiscount = getItemViewAppliedLoyaltyDiscount(view)
            priceInCurrency = cfmrTotal
            priceExcludingLoyaltyBenefits = cfmrTotal + loyaltyCfmrDiscount
            break
          default:
            let totals = view.totals
            const appliedLoyaltyUpgrade = getItemViewAppliedLoyaltyUpgrade(view)
            if (appliedLoyaltyUpgrade) {
              totals = appliedLoyaltyUpgrade.targetTotals
            }
            priceInCurrency = getItemViewTotalsPrice(totals, canCheckoutWithMemberPrice)
            break
        }

        priceByItem.set(view.item.itemId, {
          price: floatify(priceInCurrency),
          priceExcludingLoyaltyBenefits: priceExcludingLoyaltyBenefits ? floatify(priceExcludingLoyaltyBenefits) : undefined,
        })
      }
    })

    if (!priceByItem.size) return EmptyMap

    return priceByItem
  },
)

export default getCheckoutLuxLoyaltyItemsPriceInCurrency
