import { EmptyArray } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { floatify } from 'lib/maths/mathUtils'
import getCheckoutLuxLoyaltyPointBurnCalculationView from './getCheckoutLuxLoyaltyPointBurnCalculationView'

const getCheckoutLuxLoyaltyBurningPointItems = createSelector(
  (state: App.State) => state.checkout.luxLoyalty.pointsToBurn,
  (state: App.State) => getCheckoutLuxLoyaltyPointBurnCalculationView(state),
  (
    pointsToBurn,
    pointBurnCalculationView,
  ): Array<App.Checkout.LuxLoyaltyBurningPointItem> => {
    if (!pointsToBurn) return EmptyArray
    if (!pointBurnCalculationView.data) return EmptyArray
    if (pointBurnCalculationView.data.minBurnablePoints > pointsToBurn) return EmptyArray
    if (pointBurnCalculationView.data.burnableItems.length === 0) return EmptyArray

    const pointsToBurnPerItem: Array<App.Checkout.LuxLoyaltyBurningPointItem> = []
    let remainingPoints = pointsToBurn
    for (const pointBurnViewItem of pointBurnCalculationView.data.burnableItems) {
      if (remainingPoints <= 0) break
      const priceInPoints = pointBurnViewItem.actualPriceInPoints
      const pointsPaid = Math.min(priceInPoints, remainingPoints)
      const priceInCurrency = pointBurnViewItem.actualPriceInCurrency
      const pointsPaidInCurrency = floatify((pointsPaid * priceInCurrency) / priceInPoints)
      const priceExcludingLoyaltyBenefits = pointBurnViewItem.priceExcludingLoyaltyBenefits
      pointsToBurnPerItem.push({
        priceInCurrency,
        priceExcludingLoyaltyBenefits,
        priceInPoints,
        pointsPaid,
        pointsPaidInCurrency,
        mx: pointBurnViewItem.itemView.mx,
        productType: pointBurnViewItem.itemView.luxLoyaltyProductType!,
        itemTransactionKey: pointBurnViewItem.itemView.item.transactionKey,
        itemId: pointBurnViewItem.itemView.item.itemId,
      })
      remainingPoints -= pointsPaid
    }

    if (!pointsToBurnPerItem.length) return EmptyArray

    return pointsToBurnPerItem
  },
)

export default getCheckoutLuxLoyaltyBurningPointItems
