import React from 'react'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'

function LuxLoyaltyGenericInfoModal() {
  return <Modal
    title="Earning points & status credits"
    subtitle="Société"
    secondaryActionText="Learn more"
    secondaryActionTo="/societe"
  >
    <BodyText variant="large">
      As a member of Luxury Escapes Société, you&rsquo;ll earn points and status credits on every booking. Use your points to purchase escapes and build your status with Status Credits to enjoy room upgrades, lounge passes, airport transfers and more.
    </BodyText>
  </Modal>
}

export default LuxLoyaltyGenericInfoModal
