import clsx from 'clsx'
import React, { useMemo } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import TextLink from 'components/Luxkit/TextLink'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import Caption from 'components/Luxkit/Typography/Caption'
import { pluralizeToString } from 'lib/string/pluralize'
import { mediaQueryDown } from 'components/utils/breakpoint'

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme.borderRadius.S};

  &.exclusive {
    background-color: ${props => props.theme.palette.product.exclusive.background};
  }

  &.black {
    background-color: ${props => props.theme.palette.neutral.default.one};
  }

  &.variant-xs {
    height: ${rem(24)};
    padding: 0 ${rem(6)};
  }

  &.variant-small {
    padding: ${rem(6)};
  }

  &.variant-medium {
    padding: ${rem(6)};
  }

  &.variant-large {
    padding: ${rem(6)} ${rem(8)};
  }

  &.variant-xl {
    padding: ${rem(6)} ${rem(8)};
  }
`

const LabelGroup = styled(Group)`
  &.autoShowHide {
    ${mediaQueryDown.tablet} {
      display: none;
    }
  }
`

interface Props {
  link?: boolean;
  rating: number;
  total?: number;
  onlyNumber?: boolean | 'auto';
  inlineLabel?: boolean;
  hideLink?: boolean;
  variant?: 'xs' | 'small' | 'medium' | 'large' | 'xl';
  ratingSubtitle?: string;
  onReviewSubTitleClick?: () => void;
  className?: string;
  /**
   * Where the number rating will go
   * start or end of the rating count/source
   *
   * @default start
   */
  numberPosition?: 'start' | 'end';
}

function NumberRating(props: Props) {
  const {
    link,
    rating,
    total = 0,
    onlyNumber = false,
    inlineLabel = false,
    hideLink = false,
    variant = 'xl',
    ratingSubtitle,
    onReviewSubTitleClick,
    numberPosition = 'start',
    className,
  } = props

  const ratingLabel = useMemo(() => {
    if (rating >= 9.6) return 'Outstanding'
    if (rating >= 9 && rating < 9.6) return 'Exceptional'
    if (rating >= 8.6 && rating < 9) return 'Excellent'
    if (rating >= 8 && rating < 8.6) return 'Very Good'
    if (rating >= 7 && rating < 8) return 'Good'
    return 'Rating'
  }, [rating])

  const subtitle = useMemo(() => ratingSubtitle || pluralizeToString('review', total), [ratingSubtitle, total])
  const ratingText = rating === 10 ? rating : rating.toFixed(1)

  const isPremium = rating >= 7

  let ratingElement: React.ReactNode
  if (variant === 'xl') {
    ratingElement = <Heading variant="heading2" colour={isPremium ? 'exclusive-contrast' : 'neutral-eight'}>{ratingText}</Heading>
  } else if (variant === 'large') {
    ratingElement = <Subtitle variant="subtitle2" colour={isPremium ? 'exclusive-contrast' : 'neutral-eight'}>{ratingText}</Subtitle>
  } else if (variant === 'medium') {
    ratingElement = <Heading variant="heading6" colour={isPremium ? 'exclusive-contrast' : 'neutral-eight'}>{ratingText}</Heading>
  } else if (variant === 'small') {
    ratingElement = <Caption variant="large" weight="bold" colour={isPremium ? 'exclusive-contrast' : 'neutral-eight'}>{ratingText}</Caption>
  } else {
    ratingElement = <BodyText variant="small" weight="bold" colour={isPremium ? 'exclusive-contrast' : 'neutral-eight'}>{ratingText}</BodyText>
  }

  return (
    <Group
      direction={numberPosition === 'start' ? 'horizontal' : 'horizontal-reverse'}
      verticalAlign="center"
      gap={8}
      className={className}
    >
      <NumberContainer className={clsx(`variant-${variant}`, {
        exclusive: isPremium,
        black: !isPremium,
      })}
      >
        {ratingElement}
      </NumberContainer>
      {onlyNumber !== true && (
        <LabelGroup
          direction={inlineLabel ? 'horizontal' : 'vertical'}
          verticalAlign={inlineLabel ? 'center' : undefined}
          horizontalAlign={inlineLabel ? undefined : numberPosition === 'end' ? 'end' : 'start'}
          gap={inlineLabel ? 4 : undefined}
          className={clsx({ autoShowHide: onlyNumber === 'auto' })}
        >
          <BodyText
            variant={variant === 'xl' ? 'medium' : 'small'}
            weight="bold"
            colour="neutral-one"
          >
            {ratingLabel}
          </BodyText>
          {!hideLink && <>
            {link && (
              <TextLink
                size="small"
                href="#customer-reviews"
                onClick={onReviewSubTitleClick}
                weight="regular"
              >
                {subtitle}
              </TextLink>
            )}
            {!link && <BodyText
              variant="small"
              colour="neutral-three"
            >
              {subtitle}
            </BodyText>}
          </>
          }
        </LabelGroup>
      )}
    </Group>
  )
}

export default NumberRating
