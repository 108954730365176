import { EmptyArray } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import getAllItemViews from '../view/getAllItemViews'
import getCheckoutLuxLoyaltyItemsPriceInCurrency from 'checkout/selectors/luxLoyalty/getCheckoutLuxLoyaltyItemsPriceInCurrency'

const getCheckoutLuxLoyaltyPointsBurnCalculationRequests = createSelector(
  (state: App.State) => getAllItemViews(state),
  (state: App.State) => getCheckoutLuxLoyaltyItemsPriceInCurrency(state),
  (
    allItemViews,
    itemsPriceInCurrency,
  ): Array<App.Checkout.LuxLoyaltyPointBurnCalculationRequest> => {
    if (!allItemViews.hasRequiredData) return EmptyArray
    const requests: Array<App.Checkout.LuxLoyaltyPointBurnCalculationRequest> = allItemViews.data.values().reduce<Array<App.Checkout.LuxLoyaltyPointBurnCalculationRequest>>((acc, curr) => {
      const view = curr.data
      const pricing = itemsPriceInCurrency.get(view.item.itemId)
      if (view.luxLoyaltyProductType && pricing) {
        const price = pricing.priceExcludingLoyaltyBenefits ?? pricing.price
        acc.push({
          item: view.item,
          mx: view.mx,
          price,
          productType: view.luxLoyaltyProductType,
        })
      }
      return acc
    }, [])
    return requests
  },
)

export default getCheckoutLuxLoyaltyPointsBurnCalculationRequests
